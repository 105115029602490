module.exports = {
    title: "Profiles",
    new_registered_accounts: "New Registered Accounts",
    get_verified_accounts: "Get Verified Accounts",
    child_users: 'Members',
    non_verified_profile_title: "Non Verified Profiles",
    social_title: 'Social Profile Links',
    heading: {
        journey: 'Journey',
        user: 'User',
        members: 'Members',
        member: 'Member',
        users: 'Users',
        adminUser: 'Admin User',
        company: 'Company',
        branches: 'Branches',
        planDetail: 'Plan Details',
        meetingList: 'Meeting List',
        groups: 'Groups',
        groupChange :'Designation(Title) Request',
        community: 'My Community',
        chat: 'Group Chat(s)',
        jobDesignationRequests: 'Job Title (Designation) Request',
        departments: 'Departments',
        templates: 'Templates',
        ratings: 'Ratings',
        interviewers:'Interviewers',
    },
    gst:{
        gstin : 'GST NUMBER',
        panNumber:'PAN NUMBER',
        gstinStatus:'GST STATUS',
        businessName:'Business Name',
        taxpayerType:'Tax Payer',
        dateOfRegistration:'Date Of Registration',
        aadhaarValidationDate: 'Aadhaar Verification Date',
        constitutionOfBusiness:'Constitution Of Business',
        legalName:'legal Name',
        address : 'Address',
        GSTINFO : 'GST INFORMATION'

    },
    tab: {
        details: 'Details',
        branches: 'Branches',
        social_links: 'Social Links',
        verification: 'Verifications',
        profile_request: 'Profile Update Request',
        posts: 'Posts',
        opportunity: 'Opportunity',
        bts: 'BusinessTravellers',
        resources: 'Resources',
        userDetail: 'User Detail',
        recruitment: 'Job Openings',
        candidates: 'Candidates',
        otherCandidates: 'Other Candidates',
        templates: 'Templates',
    },
    crud: {
        create: "Create Profile",
        edit: "Edit Profile",
        view_social: 'Social Links',
        social_edit: 'Update Links',
        verification: 'Verification',
        profile_request: 'Profile Requests',
    },
    table: {
        role: "Role",
        freelancer: "Freelancer",
        locationChangeCount: "Location Change Count",
        type: "Type",
        companyType: "Company Type",
        name: "User Name",
        title: 'Title',
        'profile': "Profile",
        company: 'Company',
        individual: 'Individual',
        isVerified: "Is Verified?",
        status: "Status",
        isActive: 'Active',
        platform: 'Platform',
        SocialLink: 'Link',
        country: 'Country',
        state: 'State',
        city: 'City',
        email: 'Email Address',
        profileStatus:'Profile Status',
        isDraft:'Draft',
        JobTitleStatus: 'Job Title Status',
        emailStatus: 'Email Status',
        planName:'Plan Name',
        starDate : 'Plan Start Date',
        endDate :  ' Plan End Date',
        planStatus : 'Plan Status',
        createdAt:'Created At',
        group: {
            'shortName': 'Short Name',
            'group': 'Title',
            'subTitle': 'Sub Title',
            'users': 'Members',
            'asUser': 'Type',
        },
        gac: {
            category: 'Category',
            type: 'Type',
            bookmark: 'Bookmark',
            message: 'Message'
        },
        chat: {
            sender: 'User',
            chat: 'Chat',
            isRead: 'isRead?',
            created: 'Added At'
        },
        ratings: {
            logo: 'Logo',
            platform: 'Platform',
            rate: 'Ratting',
            url: 'Url',
            createdAt: 'Created At',
            updatedAt: 'Updated At'
        }
    },
    view_chat: {
        sender: 'User',
        chat: 'Chat',
        isRead: 'isRead?',
        created: 'Added At',
        attachment: 'View Attachment'
    },
    form: {
        name: "Name",
        description: "Description",
        email: 'Email Address',
        panNumber:'Pan Number',
        isVerified: "isVerified",
        isActive: 'Active',
        title: "Title",
        address1: "Location",
        address2: "Address 2",
        company: "Company",
        website: "Website",
        image: "Image",
        currentImage: 'Currently :',
        websitePlaceHolder: 'http://www.',
        status: "Status",
        latitude: "Latitude",
        longitude: "Longitude",
        city: "City",
        companyType: "Type",
        country: "Country",
        industry: "Industry",
        subIndustry: 'Services',
        state: 'State',
        user: 'User',
        chatId: 'Chat Id',
        chatPassword: 'Chat Password',
        platform: 'Platform',
        SocialLink: 'SocialLink',
        skills: 'Skills',
        companySize: 'Size',
        profileStatus:'Profile Status',
    },

    user: {
        name: 'Name',
        title: 'Title',
        email: 'Email Address',
        panNumber:'Pan Number',

        city: "City",
        state: 'State',
        address1: "Location",
        image: "Image",
        latitude: "Latitude",
        longitude: "Longitude",
        country: "Country",
        status: 'Status',
        countryCode: 'Country Code',
        role: 'Role',
    },

    filter: {
        industry: "Industry",
        subIndustry: "SubIndustry",
        city: "City",
        state: "State",
        country: "Country",
        profileVerified: 'Status',
        user: "User",
        category: 'Category',
        type: "Type",
    },
    validation: {
        required: {
            name: 'Name is required',
            skills: 'Skill is required',
            address1: 'Location is required',
            industry: 'Industry is required',
            subIndustry: 'Services is required',
            user: 'User is required',
            status: 'Status is required',
            plateform: 'platform is required',
            SocialLink: 'socialLink is required',

        },
        minLength: {
            name: 'Name must have at least',
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
