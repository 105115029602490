import Vue from 'vue'
import Router from 'vue-router'
import GetVerifiedList from "../views/profile/GetVerifiedList";
// Containers
const TheContainer = () => import('@/containers/TheContainer')
// Views
const SignIn = () => import('@/views/SignIn')
const ChangePassword = () => import('@/views/ChangePassword')
const Logout = () => import('@/views/Logout')
const Dashboard = () => import('@/views/Dashboard')

// Views - Components

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')
const Page404 = () => import('@/views/pages/Page404')
const Page403 = () => import('@/views/pages/Page403')
const Page500 = () => import('@/views/pages/Page500')
// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
const Country = () => import('@/views/country/Country')
const State = () => import('@/views/state/State')
const Stream = () => import('@/views/stream/Stream')
const City = () => import('@/views/city/City')
const Discipline = () => import('@/views/discipline/Discipline')
const Language = () => import('@/views/language/Language')
const WorkType = () => import('@/views/worktype/WorkType')
const Domain = () => import('@/views/domain/Domain')
const CollegeDepartment = () => import('@/views/collegedepartment/CollegeDepartment')
const Universities = () => import('@/views/universities/Universities')
const Programme = () => import('@/views/programme/Programme')
const GroupCategoryType = () => import('@/views/groupCategoryType/GroupCategoryType')
const BusinessTraveller = () => import('@/views/businessTraveller/BusinessTraveller')
const BusinessView = () => import('@/views/businessTraveller/BusinessView')
const BusinessTravellerCreate = () => import('@/views/businessTraveller/BusinessTravellerCreate')
const BusinessTravellerEdit = () => import('@/views/businessTraveller/BusinessTravellerEdit')
const BusinessTravellerReportList = () => import('@/views/businessTraveller/BusinessReport')
const BusinessTravellerResponseList = () => import('@/views/businessTraveller/BusinessResponse')


const AdminSettings = () => import('@/views/adminSettings/AdminSetting')
const CompanyType = () => import('@/views/companytype/CompanyType')
const CompanySize = () => import('@/views/companysize/CompanySize')
const Price = () => import('@/views/price/Price')
const ResourceType = () => import('@/views/resourcetype/ResourceType')
const Priority = () => import('@/views/priority/Priority')
const Duration = () => import('@/views/duration/Duration')
const Availability = () => import('@/views/availability/Availability')
const Experiences = () => import('@/views/experiences/Experiences')
const Skills = () => import('@/views/skills/Skills')
const OtherSkill = () => import('@/views/skills/OtherSkill')
const MergeSkill = () => import('@/views/skills/MergeSkill')
const CurrencyType = () => import('@/views/currencytype/CurrencyType')
const SocialPlatform = () => import('@/views/socialplatform/SocialPlatform')
const Industry = () => import('@/views/industry/Industry')
const ReportReason = () => import('@/views/reportreason/ReportReason')
const Notification = () => import('@/views/notification/Notification')
const Post = () => import('@/views/post/Post')
const PostCreate = () => import('@/views/post/PostCreate')
const PostEdit = () => import('@/views/post/PostEdit')
const PostReportList = () => import('@/views/post/PostReport')
const PostResponseList = () => import('@/views/post/PostResponse')
const PostMedia = () => import('@/views/post/PostMedia')
const PostView = () => import('@/views/post/PostView')

const Resource = () => import('@/views/resource/Resource')
const ResourceCreate = () => import('@/views/resource/ResourceCreate')
const ResourceEdit = () => import('@/views/resource/ResourceEdit')
const ResourceView = () => import('@/views/resource/ResourceView')

const Discount = () => import('@/views/discount/Discount')
const DiscountCreate = () => import('@/views/discount/DiscountCreate')
const DiscountEdit = () => import('@/views/discount/DiscountEdit')
const DiscountView = () => import('@/views/discount/DiscountView')

const DiscountUsers = () => import('@/views/discountUsers/DiscountUsers')
const DiscountUsersView = () => import('@/views/discountUsers/DiscountUsersView')
const College = () => import('@/views/college/College')
const CollegeView = () => import('@/views/college/CollegeView')
const CollegeEmployer = () => import('@/views/college/CollegeEmployer')
const EditCollegePlanPermissions = () => import('@/views/college/tab/EditPlanPermissions')
const CollegeStaff = () => import('@/views/college/CollegeStaff')

const Company = () => import('@/views/company/Company')
const CompanyCreate = () => import('@/views/company/CompanyCreate')
const CompanyEdit = () => import('@/views/company/CompanyEdit')
const CompanyView = () => import('@/views/company/CompanyView')

const Branch = () => import('@/views/branch/Branch')
//const BranchCreate = () => import('@/views/branch/BranchCreate')
//const BranchEdit = () => import('@/views/branch/BranchCreate')
//const BranchView = () => import('@/views/branch/BranchView')

const PlatformRatting = () => import('@/views/platformRatting/PlatformRatting')

const StudentList = () => import('@/views/student/Student')
const StudentView = () => import('@/views/student/View')
const ProjectView = () => import('@/views/student/ProjectView')

const Opportunity = () => import('@/views/opportunity/Opportunity')
//const OpportunityCreate = () => import('@/views/opportunity/OpportunityCreate')
const OpportunityView = () => import('@/views/opportunity/OpportunityView')
const OpportunityResourceView = () => import('@/views/opportunity/tab/OpportunityResourceView')
const OpportunityCandidateList = () => import('@/views/opportunity/OpportunityCandidateListTab')
// const OpportunityResourceDetail = () => import('@/views/opportunity/tab/OpportunityResourceDetail')
// const OpportunityResourceMedia = () => import('@/views/opportunity/tab/OpportunityResourceMedia')

const EExperience = () => import('@/views/eexperience/EExperience')
const EExperienceDetailtabs = () => import('@/views/eexperience/Detailtabs')

const CompaniesResources = () => import('@/views/companies/CompaniesResources');
const Profile = () => import('@/views/profile/Profile')
const NewRegistration = () => import('@/views/profile/NewRegistration')
const VerificationRequestList = () => import('@/views/profile/GetVerifiedList')

const PendingProfilesList = () => import('@/views/profile/PendingProfilesList')
const PendingCollegesList = () => import('@/views/college/PendingCollegesList')
const ProfileTabs = () => import('@/views/profile/ProfileTabs')
const ProfileTabsOtp = () => import('@/views/profile/ProfileTabsOtp')
const ProfileForVerifier = () => import('@/views/profile/ProfileForVerifier')
const GroupRequestView = () => import('@/views/profile/tab/GroupRequestDetail')
const CollegeGroupRequestView = () => import('@/views/college/tab/GroupRequestDetail')
//const ProfileCreate = () => import('@/views/profile/ProfileCreate')
const ProfileEdit = () => import('@/views/profile/ProfileEdit')
const ProfileVerification = () => import('@/views/profile/ProfileVerification')
const CollegeVerification = () => import('@/views/college/CollegeVerification')
const ProfileSocialLinks = () => import('@/views/profile/ProfileLinks')
const ProfileAllEntities = () => import('@/views/profile/AllEntitiesDetails')
const MyGiveAndAskCategories = () => import('@/views/profile/GAndACategories')
const MyGiveAndAskCategoriesDetail = () => import('@/views/profile/GAndACategoriesDetail')
const GAndAChat = () => import('@/views/profile/gacChat')
const PollComments = () => import('@/views/profile/tab/PollComments')
const EventComments = () => import('@/views/profile/tab/EventComments')
const PollUserResponses = () => import('@/views/profile/tab/PollUserResponses')
const PollUserReports = () => import('@/views/profile/tab/PollUserReports')
const EventUserReports = () => import('@/views/profile/tab/EventUserReports')

const CollegeMyGiveAndAskCategories = () => import('@/views/college/GAndACategories')
const CollegeGAndAChat = () => import('@/views/college/gacChat')
const CollegePollComments = () => import('@/views/college/tab/PollComments')
const CollegeEventComments = () => import('@/views/college/tab/EventComments')
const CollegePollUserResponses = () => import('@/views/college/tab/PollUserResponses')
const CollegePollUserReports = () => import('@/views/college/tab/PollUserReports')
const CollegeEventUserReports = () => import('@/views/college/tab/EventUserReports')

const ProfileUpdateRequest = () => import('@/views/profile/ProfileUpdateRequest')
const PendingProfileUpdateRequest = () => import('@/views/profile/PendingProfileUpdateRequest')
const PendingCollegeUpdateRequest = () => import('@/views/college/PendingCollegeUpdateRequest')
//Admin Profile
const AdminProfile = () => import('@/views/adminProfile/AdminProfile')
/*Admin can create staff*/
const Staff = () => import('@/views/users/Users')  //Staff List
const StaffCreate = () => import('@/views/users/UserCreate') // Staff Crate
const StaffEdit = () => import('@/views/users/UserEdit') //Staff Edit
const StaffView = () => import('@/views/users/UserView') //Staff View

const DeletedStaff = () => import('@/views/users/DeletedStaff')
const CompanyViews = () => import('@/views/users/CompanyView')

/*Admin can create staff*/
const UID = () => import('@/views/uniqueidentification/UID')  //Staff List

const ServicesTax = () => import('@/views/companyservicestax/CompanyServicesTax')  //Staff List

/*Role*/
const Role = () => import('@/views/role/Role')
const RoleCreate = () => import('@/views/role/RoleCreate')
const RoleEdit = () => import('@/views/role/RoleEdit')

const Rules = () => import('@/views/rules/Rules')
const RulesCreate = () => import('@/views/rules/RulesCreate')
const RulesEdit = () => import('@/views/rules/RulesEdit')

const PricingTypes = () => import('@/views/pricingTypes/PricingTypes')

const UserTitles = () => import('@/views/usertitles/UserTitles')

const Cms = () => import('@/views/cms/cms')
const CmsCreate = () => import('@/views/cms/cmsCreate')
const CmsEdit = () => import('@/views/cms/cmsEdit')

const emailTemplate = () => import('@/views/emailtemplate/emailTemplate')
const emailTemplateCreate = () => import('@/views/emailtemplate/emailTemplateCreate')
const emailTemplateEdit = () => import('@/views/emailtemplate/emailTemplateEdit')

const Viewer = () => import('@/views/viewer/Viewer')
const ViewerDoc = () => import('@/views/viewer/ViewerDoc')
const ViewerXls = () => import('@/views/viewer/ViewerXls')
const ViewerRegDoc = () => import('@/views/viewer/ViewerRegDoc')

const Forgotpassword = () => import('@/views/Forgotpassword')
const Resetpassword = () => import('@/views/Resetpassword')
const NotificationAlerts = () => import('@/views/Notification')

const GroupsOfUsers = () => import('@/views/giveandask/Groups')
const GiveAndAsk = () => import('@/views/giveandask/GiveAndAsk')
const GiveAndAskCategories = () => import('@/views/giveandask/GiveAndAskCategories')
const Detail = () => import('@/views/giveandask/Detail')
const GroupPostChat = () => import('@/views/giveandask/tab/postChat')
const GroupPollComments = () => import('@/views/giveandask/tab/groupPollComments')
const GroupEventComments = () => import('@/views/giveandask/tab/groupEventComments')
const GroupPollUserResponses = () => import('@/views/giveandask/tab/groupPollUserResponses')
const GroupPollUserReports = () => import('@/views/giveandask/tab/groupPollUserReports')
const GroupEventUserReports = () => import('@/views/giveandask/tab/groupEventUserReports')
const GroupVerificationRequests = () =>import('@/views/groupVerificationRequests/list')
const GroupTitleVerificationList = () =>import('@/views/groupVerificationRequests/GroupTitleVerificationList')
const GroupVerificationRequestsView = () =>import('@/views/groupVerificationRequests/view')

const FeedbackList = () => import('@/views/feedbacks/Feedbacks')
const FeedbackView = () => import('@/views/feedbacks/FeedbackView')
const ContactList = () => import('@/views/contact-us/ContactUs')

const InquiryList = () => import('@/views/inquiry/Inquiry')
const InquiryView = () => import('@/views/inquiry/InquiryView')

const TransactionsList = () => import('@/views/transactions/Transactions')
const TransactionView = () => import('@/views/transactions/TransactionView')


//Plan
const PlanCreate = () => import('@/views/plan/PlanCreate')
const PlanEdit = () => import('@/views/plan/PlanEdit')
const PlanView = () => import('@/views/plan/PlanView')
const Plan = () => import('@/views/plan/Plan')

//User currentedit Plan permissions
const EditPlanPermissions = () => import('@/views/profile/tab/EditPlanPermissions')
const ViewPlanPermissions = () => import('@/views/profile/tab/ViewPlanPermissions')

//Announcement
const AnnouncementCreate = () => import('@/views/announcement/AnnouncementCreate')
const AnnouncementView = () => import('@/views/announcement/AnnouncementView')
const Announcement = () => import('@/views/announcement/Announcement')

//Version
const VersionCreate = () => import('@/views/version/VersionCreate')
const Version = () => import('@/views/version/Version')

// Calendar
const Calendar = () => import('@/views/calendar/Calendar')
const CalendarView = () => import('@/views/calendar/CalendarView')

//const Recruitments = () => import('@/views/recruitments/Recruitments')
//const RecruitmentView = () => import('@/views/recruitments/RecruitmentView')

//const Candidates = () => import('@/views/candidates/Candidates')
//const CandidateView = () => import('@/views/candidates/CandidateView')
//const JobCandidateList = () => import('@/views/recruitments/CandidateListTab')
// const Recruiters = () => import('@/views/recruiterResources/RecruiterResources')
// const RecruiterResourcesView = () => import('@/views/recruiterResources/RecruiterResourcesView')

//Reporting
const ReportedUsers = () => import('@/views/report/ReportedUsers')
const ReportedUserView = () => import('@/views/report/ReportedUserView')

const OpportunityReports = () => import('@/views/report/OpportunityReport')
const JobOpeningsReports = () => import('@/views/report/JobReport')
const ResourcesReports = () => import('@/views/report/ResourceReport')
//const CandidatesReports = () => import('@/views/report/CandidatesReport')
const CompaniesReports = () => import('@/views/report/CompanyReport')
const UsersReports = () => import('@/views/report/UsersReport')
const GroupsReports = () => import('@/views/report/GroupsReport')

//Email Delivery Logs
const EmailDelivery = () => import('@/views/emailDelivery/EmailDelivery')

const Educations = () => import('@/views/educations/Educations')
const MergeEducations = () => import('@/views/educations/MergeEducations')
const OtherEducation = () => import('@/views/educations/OtherEducation')

Vue.use(Router)
const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
});

function configRoutes() {
    // {
    //     path: '/:lang',
    //     component: {
    //         template: '<router-view></router-view>'
    //     },
    //     beforeEnter(to,from,next) {
    //         const lang = to.params.lang
    //         if(!['en','gu'].includes(lang)) return next('en')
    //
    //         if(i18n.locale!== lang){
    //             i18n.locale = lang
    //         }
    //         return next()
    //     }
    // },

    return [
        {
            path: '/logout',
            component: Logout,
            name: 'Logout',
            meta: {
                title: "Logout",
                requiresAuth: true
            }
        },
        {
            path: '/signin',
            name: 'SignIn',
            component: SignIn,
        },
        {
            path: '/forgot_password',
            name: 'Forgotpassword',
            component: Forgotpassword
        },
        {
            path: '/password/reset/:token',
            name: 'Resetpassword',
            component: Resetpassword
        },
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: TheContainer,
            // beforeEnter: guard, // Using guard before entering the route
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {
                        title: "Dashboard",
                        requiresAuth: true
                    },
                },
                {
                    path: '/users/profile/pending-update-request/list',
                    name: 'profilerEQUESTlIST',
                    component: PendingProfileUpdateRequest,
                    meta: {
                        title: "Profile Update Request",
                        requiresAuth: true
                    },
                },
                {
                    path: '/change-password',
                    name: 'ChangePassword',
                    component: ChangePassword,
                },
                {
                    path: 'country',
                    meta: {
                        label: 'Country',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Country',
                            component: Country,
                            meta: {
                                label: 'Country',
                                requiresAuth: true,
                                permission: 'list-country',
                            },
                        },
                    ]
                },
                {
                    path: 'pricing-types',
                    meta: {
                        label: 'Pricing Types',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Pricing Types',
                            component: PricingTypes,
                            meta: {
                                label: 'Pricing Types',
                                requiresAuth: true,
                                permission: 'list-pricing-types',
                            },
                        },
                    ]
                },
                {
                    path: 'user-titles',
                    meta: {
                        label: 'User Titles',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'User Titles',
                            component: UserTitles,
                            meta: {
                                label: 'User Titles',
                                requiresAuth: true,
                                permission: 'list-user-title',
                            },
                        },
                    ]
                },
                {
                    path: 'my-profile',
                    redirect: '',
                    name: 'myProfile',
                    meta: {
                        label: 'My Profile',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'adminProfile',
                            requiresAuth: true,
                            component: AdminProfile,
                            meta: {
                                label: 'Admin Profile',
                            },
                        }
                    ]
                },
                {
                    path: 'deleted-users',
                    redirect: '',
                    name: 'Trash Users',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Trash Users',
                            requiresAuth: true,
                            component: DeletedStaff,
                        },
                        {
                            path: 'details/:id',
                            name: 'Detail',
                            requiresAuth: true,
                            component: CompanyViews,
                        },
                    ]
                },
                {
                    path: 'plan',
                    meta: {
                        label: 'Plan',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Plan',
                            component: Plan
                        },
                        {
                            path: 'create',
                            name: 'Create Plan',
                            component: PlanCreate
                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Plan',
                            component: PlanEdit
                        },
                        {
                            path: 'view/:id',
                            name: 'View Plan',
                            component: PlanView
                        },
                    ]
                },
                {
                    path: 'announcements',
                    meta: {
                        label: 'Announcement',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Announcement',
                            component: Announcement
                        },
                        {
                            path: 'create',
                            name: 'Create Announcement',
                            component: AnnouncementCreate
                        },
                        {
                            path: 'view/:id',
                            name: 'View Announcement',
                            component: AnnouncementView
                        },
                    ]
                },
                {
                    path: 'version',
                    meta: {
                        label: 'Version',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Version',
                            component: Version
                        },
                        {
                            path: 'create',
                            name: 'Create Version',
                            component: VersionCreate
                        },
                    ]
                },
                {
                    path: 'reporting',
                    meta: {
                        label: 'Reported Users',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Reported Users',
                            component: ReportedUsers
                        },
                        {
                            path: 'view/:id',
                            name: 'Reported User View',
                            component: ReportedUserView
                        },
                    ]
                },
                {
                    path: 'users',
                    redirect: '',
                    name: 'Staff',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Staff',
                            requiresAuth: true,
                            component: Staff,
                        },
                        {
                            path: 'deleted-users',
                            name: 'Deleted Staff',
                            requiresAuth: true,
                            component: DeletedStaff,
                        },
                        {
                            path: 'create',
                            name: 'Create Staff',
                            requiresAuth: true,
                            component: StaffCreate,

                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Staff',
                            requiresAuth: true,
                            component: StaffEdit
                        },
                        {
                            path: 'view/:id',
                            name: 'View Staff',
                            requiresAuth: true,
                            component: StaffView
                        },
                    ]
                },

                {
                    path: 'feedbacks',
                    redirect: '',
                    name: 'Feedbacks',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Feedback',
                            requiresAuth: true,
                            component: FeedbackList,
                        },
                        {
                            path: 'view/:id',
                            name: 'FeedbackView',
                            requiresAuth: true,
                            component: FeedbackView,
                        },
                    ]
                },
                {
                    path: 'inquiry',
                    redirect: '',
                    name: 'Inquiry',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Inquiry',
                            requiresAuth: true,
                            component: InquiryList,

                        },
                        {
                            path: 'view/:id',
                            name: 'InquiryView',
                            requiresAuth: true,
                            component: InquiryView,

                        },
                    ]
                },
                {
                    path: 'email-delivery',
                    redirect: '',
                    name: 'Email Delivery Logs',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Email Delivery Logs',
                            requiresAuth: true,
                            component: EmailDelivery,

                        },
                    ]
                },
                {
                    path: 'contact-us',
                    redirect: '',
                    name: 'Contact Us',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Contact Us',
                            requiresAuth: true,
                            component: ContactList,

                        },
                    ]
                },

                {
                    path: 'calendar',
                    redirect: '',
                    name: 'Calendar',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Calendar',
                            requiresAuth: true,
                            component: Calendar,

                        },
                        {
                            path: 'show',
                            name: 'Calendar View',
                            requiresAuth: true,
                            component: CalendarView,
                        },
                        {
                            path: 'show/:id',
                            name: 'Calendar View',
                            requiresAuth: true,
                            component: CalendarView,
                        },
                    ]
                },
                {
                    path: 'reportreason',
                    meta: {
                        label: 'Report Reason',
                        requiresAuth: true,
                        permission: 'list-report-reason',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'reportreason',
                            requiresAuth: true,
                            component: ReportReason,
                            meta: {
                                label: "Report Reason",
                            },
                        },
                    ]
                },

                {
                    path: 'notifications/alerts',
                    meta: {
                        label: 'Notification',
                        requiresAuth: true,
                        //permission: 'list-notification',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'notificationAlerts',
                            component: NotificationAlerts,
                            meta: {
                                label: 'Notification',
                            },
                        },
                    ]
                },
                {
                    path: 'business-traveller',
                    meta: {
                        label: 'Business Travellers',
                        requiresAuth: true,
                        permission: 'list-business-traveller',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'BusinessTravellers',
                            component: BusinessTraveller,
                            meta: {
                                label: 'Business Travellers',
                            },
                        },
                        {
                            path: 'create',
                            name: 'BusinessTraveller Create',
                            meta: {
                                label: 'Business Travellers Create',
                            },
                            component: BusinessTravellerCreate
                        },
                        {
                            path: 'edit/:id',
                            name: 'BusinessTraveller Edit',
                            meta: {
                                label: 'Business Travellers Edit',
                            },
                            component: BusinessTravellerEdit
                        },
                        {
                            path: 'view/:id/:user_id?',
                            name: 'BusinessTraveller View',
                            meta: {
                                label: 'Business Travellers View',
                            },
                            component: BusinessView
                        },
                        {
                            path: 'report/list/:id',
                            name: 'ReportList',
                            meta: {
                                label: 'Report List',
                            },
                            component: BusinessTravellerReportList
                        },
                        {
                            path: 'response/list/:id',
                            name: 'ResponseList',
                            meta: {
                                label: 'Response List',
                            },
                            component: BusinessTravellerResponseList
                        },
                    ]
                },
                {
                    path: 'worktype',
                    meta: {
                        label: 'Work Type',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'WorkType',
                            component: WorkType,
                            meta: {
                                label: 'Work Type',
                                requiresAuth: true,
                                permission: 'list-workType',
                            },
                        },
                    ]
                },
                {
                    path: 'domain',
                    meta: {
                        label: 'Domain',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'domain',
                            component: Domain,
                            meta: {
                                label: 'Domain',
                                requiresAuth: true,
                                permission: 'list-domain',
                            },
                        },
                    ]
                },
                {
                    path: 'groups-category-type',
                    meta: {
                        label: 'Group Category Type',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'GroupCategoryType',
                            component: GroupCategoryType,
                            meta: {
                                label: 'Group Category Type',
                                requiresAuth: true,
                                permission: 'list-group-category-type',
                            },
                        },
                    ]
                },


                {
                    path: 'user-uid',
                    meta: {
                        label: 'Unique Identification Names',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'UniqueIdentificationNames',
                            component: UID,
                            meta: {
                                label: 'Unique Identification Names',
                                requiresAuth: true,
                                //permission: 'list-workType',
                            },
                        },
                    ]
                },

                {
                    path: 'services-tax',
                    meta: {
                        label: 'Company Service Tax',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Service Tax',
                            component: ServicesTax,
                            meta: {
                                label: 'Company Service Tax',
                                requiresAuth: true,
                                permission: 'list-service-tax',
                            },
                        },
                    ]
                },

                {
                    path: 'resourcetype',
                    meta: {
                        label: 'Resource Type',
                        requiresAuth: true,
                        permission: 'list-resourceType',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'ResourceType',
                            meta: {
                                label: 'Resource Type',
                            },
                            component: ResourceType
                        },
                    ]
                },
                {
                    path: 'priority',
                    meta: {
                        label: 'Priority',
                        requiresAuth: true,
                        permission: 'list-priority',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Priority',
                            component: Priority
                        },
                    ]
                },
                {
                    path: 'duration',
                    meta: {
                        label: 'Duration',
                        requiresAuth: true,
                        permission: 'list-duration',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Duration',
                            component: Duration
                        },
                    ]
                }, {
                    path: 'availability',
                    meta: {
                        label: 'Availability',
                        requiresAuth: true,
                        permission: 'list-availability',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Availability',
                            component: Availability
                        },
                    ]
                }, {
                    path: 'experience',
                    meta: {
                        label: 'Experience',
                        requiresAuth: true,
                        permission: 'list-experience',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Experience',
                            component: Experiences
                        },
                    ]
                },
                {
                    path: 'skills',
                    meta: {
                        label: 'Skills',
                        requiresAuth: true,
                        permission: 'list-skills',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Skills',
                            component: Skills
                        },
                        {
                            path: 'merge/:id',
                            name: 'MergeSkill',
                            component: MergeSkill
                        },
                        {
                            path: 'other',
                            name: 'OtherSkill',
                            component: OtherSkill
                        },
                    ]
                },
                {
                    path: 'educations',
                    meta: {
                        label: 'Educations',
                        requiresAuth: true,
                        permission: 'list-education',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Educations',
                            component: Educations
                        },
                        {
                            path: 'merge/:id',
                            name: 'MergeEducation',
                            component: MergeEducations
                        },
                        {
                            path: 'other',
                            name: 'OtherEducation',
                            component: OtherEducation
                        },
                    ]
                },

                {
                    path: 'currencytype',
                    meta: {
                        label: 'Currency Type',
                        requiresAuth: true,
                        permission: 'list-currencyType',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'CurrencyType',
                            meta: {
                                label: 'Currency Type',
                            },
                            component: CurrencyType
                        },
                    ]
                },

                {
                    path: 'socialplatform',
                    meta: {
                        label: 'Social Platform',
                        requiresAuth: true,
                        permission: 'list-socialPlatform',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'SocialPlatform',
                            meta: {
                                label: 'Social Platform',
                            },
                            component: SocialPlatform
                        },
                    ]
                },
                {
                    path: 'companytype',
                    meta: {
                        label: 'Company Type',
                        requiresAuth: true,
                        permission: 'list-companyType',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'CompanyType',
                            meta: {
                                label: 'Company Type',
                            },
                            component: CompanyType
                        },
                    ]
                },

                {
                    path: 'company-size',
                    meta: {
                        label: 'Company Size',
                        requiresAuth: true,
                        permission: 'list-company-size',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'companySize',
                            meta: {
                                label: 'Company Size',
                            },
                            component: CompanySize
                        },
                    ]
                },
                {
                    path: 'price',
                    meta: {
                        label: 'Price',
                        requiresAuth: true,
                        permission: 'list-price',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'price',
                            meta: {
                                label: 'Price',
                            },
                            component: Price
                        },
                    ]
                },
                {
                    path: 'college-department',
                    meta: {
                        label: 'College Department',
                        requiresAuth: true,
                        permission: 'list-college-department',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'collegeDepartment',
                            meta: {
                                label: 'College Department',
                            },
                            component: CollegeDepartment
                        },
                    ]
                },
                {
                    path: 'universities',
                    meta: {
                        label: 'Universities',
                        requiresAuth: true,
                        permission: 'list-universities',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'universities',
                            meta: {
                                label: 'Universities',
                            },
                            component: Universities
                        },
                    ]
                },
                {
                    path: 'programme',
                    meta: {
                        label: 'Programme',
                        requiresAuth: true,
                        permission: 'list-programme',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'programme',
                            meta: {
                                label: 'Programme',
                            },
                            component: Programme
                        },
                    ]
                },
                {
                    path: 'state',
                    meta: {
                        label: 'State',
                        requiresAuth: true,
                        permission: 'list-state',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'State',
                            component: State
                        },
                    ]
                },
                {
                    path: 'stream',
                    meta: {
                        label: 'Stream',
                        requiresAuth: true,
                        permission: 'list-stream',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Stream',
                            component: Stream
                        },
                    ]
                },
                {
                    path: 'city',
                    meta: {
                        label: 'City',
                        requiresAuth: true,
                        permission: 'list-city',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'City',
                            component: City,
                        },
                    ]
                },
                {
                    path: 'discipline',
                    meta: {
                        label: 'Discipline',
                        requiresAuth: true,
                        permission: 'list-discipline',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Discipline',
                            component: Discipline,
                        },
                    ]
                },
                {
                    path: 'ga',
                    meta: {
                        label: 'Give And Ask',
                        requiresAuth: true,
                        //permission: 'list-group',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Give And Ask',
                            component: GiveAndAsk
                        },
                    ]
                },
                {
                    path: 'groups/',
                    meta: {
                        label: 'Groups',
                        requiresAuth: true,
                        permission: 'list-gac-categories',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Groups',
                            component: GroupsOfUsers
                        },
                        {
                            path: 'detail/:id?',
                            name: 'Groups Detail',
                            component: Detail
                        },

                        {
                            path: 'detail/post/chat/:id',
                            name: 'Post Chat',
                            component: GroupPostChat
                        },
                        {
                            path: 'poll/comments/:id',
                            name: 'Poll Comments',
                            component: GroupPollComments
                        },
                        {
                            path: 'event/comments/:id',
                            name: 'Poll Comments',
                            component: GroupEventComments
                        },
                        {
                            path: 'event/reports/:id',
                            name: 'Event User Report',
                            component: GroupEventUserReports
                        },
                        {
                            path: 'poll/comments/responses/:id',
                            name: 'Poll User responses',
                            component: GroupPollUserResponses
                        },
                        {
                            path: 'poll/reports/:id',
                            name: 'Poll User reports',
                            component: GroupPollUserReports
                        },
                        {
                            path: '',
                            name: 'Give And Ask Categories',
                            component: GiveAndAskCategories
                        },
                    ]
                },
                {
                    path: 'groups-verification-list',
                    meta: {
                        label: 'Group Verification Requests',
                        requiresAuth: true,
                        permission: 'list-group',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Groups Verification Requests',
                            component: GroupVerificationRequests
                        },
                        {
                            path: 'view/:id',
                            name: 'Groups Verification Request View',
                            component: GroupVerificationRequestsView
                        },

                    ]
                },
                {
                    path: 'groups-title-verification-list',
                    meta: {
                        label: 'Group Verification Requests',
                        requiresAuth: true,
                        permission: 'list-group-verification-request',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Groups Verification Requests',
                            component: GroupTitleVerificationList
                        },
                        {
                            path: 'view/:id',
                            name: 'Groups Verification Request View',
                            component: GroupVerificationRequestsView
                        },

                    ]
                },

                {
                    path: '/notifications',
                    meta: {
                        label: 'Notification',
                        requiresAuth: true,
                        permission: 'list-notification',
                    },
                    component: Notification,
                },
//                {
//                    path: '/job-openings',
//                    meta: {
//                        label: 'Job Openings',
//                        requiresAuth: true,
//                        permission: 'list-job-opening',
//                    },
//                    component: {
//                        render(c) {
//                            return c('router-view')
//                        }
//                    },
//                    children: [
//                        {
//                            path: '',
//                            name: 'Recruitments',
//                            component: Recruitments,
//                        },
//                        {
//                            path: 'view/:id/:user_id?',
//                            name: 'RecruitmentView',
//                            meta: {
//                                label: 'Recruitment View'
//                            },
//                            component: RecruitmentView
//                        },
//                        {
//                            path: 'candidates/:id',
//                            name: 'Candidates',
//                            meta: {
//                                label: 'Candidates List'
//                            },
//                            component: JobCandidateList
//                        },
//                    ]
//                },
//                {
//                    path: '/candidate',
//                    meta: {
//                        label: 'Candidates',
//                        requiresAuth: true,
//                        permission: 'list-candidate',
//                    },
//                    component: {
//                        render(c) {
//                            return c('router-view')
//                        }
//                    },
//                    children: [
//                        {
//                            path: '',
//                            name: 'Candidates',
//                            component: Candidates,
//                        },
//                        {
//                            path: 'view/:id/:user_id?',
//                            name: 'Candidate View',
//                            meta: {
//                                label: 'Candidate View'
//                            },
//                            component: CandidateView
//                        },
//                    ]
//                },
                // {
                //     path: '/recruiter-resources',
                //     meta: {
                //         label: 'Recruiter Resources',
                //         requiresAuth: true,
                //         permission: 'list-recruiter-resources',
                //     },
                //     component: {
                //         render(c) {
                //             return c('router-view')
                //         }
                //     },
                //     children: [
                //         {
                //             path: '',
                //             name: 'Recruiter Resources',
                //             component: Recruiters,
                //         },
                //         {
                //             path: 'view/:id/:user_id?',
                //             name: 'Recruiter Resource View',
                //             meta: {
                //                 label: 'Recruiter Resource View'
                //             },
                //             component: RecruiterResourcesView
                //         },
                //     ]
                // },
                {
                    path: 'industry',
                    meta: {
                        label: 'Industry',
                        requiresAuth: true,
                        permission: 'list-industry',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Industry',
                            component: Industry,
                        },
                    ]
                },

                {
                    path: 'posts',
                    meta: {
                        label: 'Posts',
                        requiresAuth: true,
                        permission: 'list-post',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Posts',
                            requiresAuth: true,
                            // linkActiveClass: "c-active",
                            component: Post
                        },
                        {
                            path: 'create',
                            name: 'Post Create',
                            requiresAuth: true,
                            // linkActiveClass: "c-active",
                            component: PostCreate
                        },
                        {
                            path: 'edit/:id',
                            name: 'Post Edit',
                            requiresAuth: true,
                            component: PostEdit
                        },
                        {
                            path: 'view/:id/:user_id?',
                            name: 'Post View',
                            requiresAuth: true,
                            component: PostView
                        },
                        {
                            path: 'media/list/:id',
                            name: 'Post Media',
                            requiresAuth: true,
                            component: PostMedia
                        },
                        {
                            path: 'report/list/:id',
                            name: 'Post Report List',
                            requiresAuth: true,
                            component: PostReportList
                        },
                        {
                            path: 'response/list/:id',
                            name: 'Post Response List',
                            requiresAuth: true,
                            component: PostResponseList
                        },
                    ]
                },
                {
                    path: 'e-experience',
                    meta: {
                        label: 'E-Experience',
                        requiresAuth: true,
                        permission: 'list-e-experience',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'E-Experience',
                            requiresAuth: true,
                            component: EExperience
                        },
                        {
                            path: 'view/:id/:user_id?',
                            name: 'E-Experience View',
                            requiresAuth: true,
                            component: EExperienceDetailtabs
                        },
                    ]
                },
                {
                    path: 'opportunities',
                    meta: {
                        label: 'Opportunities',
                        permission: 'list-opportunity',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Opportunities',
                            meta: {
                                label: 'Opportunities',
                            },
                            component: Opportunity
                        },
//                        {
//                            path: 'create',
//                            name: 'Opportunity Create',
//                            meta: {
//                                label: 'Opportunity Create',
//                            },
//                            component: OpportunityCreate
//                        },
                        {
                            path: 'view/:id/:user_id?',
                            name: 'View Opportunity',
                            meta: {
                                label: 'View Opportunity',
                            },
                            component: OpportunityView
                        },
                        {
                            path: 'resource/view/:id',
                            name: 'View Opportunity Resource',
                            meta: {
                                label: 'View Opportunity Resource',
                            },
                            component: OpportunityResourceView
                        },
                        {
                            path: 'candidates/:id',
                            name: 'Candidates',
                            meta: {
                                label: 'Candidates List'
                            },
                            component: OpportunityCandidateList
                        },
                    ]
                },
                {
                    path: 'student',
                    meta: {
                        label: 'Student',
                        permission: 'student-list',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Student',
                            meta: {
                                label: 'Student',
                            },
                            component: StudentList
                        },
                        {
                            path: 'view/:id',
                            name: 'View Student',
                            meta: {
                                label: 'View Student',
                            },
                            component: StudentView
                        },
                        {
                            path: 'view/project/:id',
                            name: 'View Project',
                            meta: {
                                label: 'View Project',
                            },
                            component: ProjectView
                        },
                    ]
                },
                {
                    path: 'rules',
                    meta: {
                        label: 'Rules',
                        requiresAuth: true,
                        permission: 'list-rules',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Rules',
                            component: Rules
                        },
                        {
                            path: 'create',
                            name: 'Add Rule',
                            component: RulesCreate
                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Rules',
                            component: RulesEdit
                        },
                    ]
                },

                {
                    path: 'cms',
                    meta: {
                        label: 'Cms Pages',
                        requiresAuth: true,
                        permission: 'list-cms-page',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'cms',
                            component: Cms,
                            meta: {
                                label: 'Cms Pages'
                            },
                        },
                        {
                            path: 'create',
                            name: 'Add Page',
                            component: CmsCreate
                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Page',
                            component: CmsEdit
                        },
                    ]
                },


                {
                    path: 'email-template',
                    meta: {
                        label: 'Email Template',
                        requiresAuth: true,
                       // permission: 'list-cms-page',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'email-template',
                            component: emailTemplate,
                            meta: {
                                label: 'Email Template'
                            },
                        },
                        {
                            path: 'create',
                            name: 'Add Email Template',
                            component: emailTemplateCreate
                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Email Template',
                            component: emailTemplateEdit
                        },
                    ]
                },


                {
                    path: 'update-request-profiles/:id?',
                    meta: {
                        requiresAuth: true,
                        permission: 'list-profile-update-request',
                        label: 'Profile updated request',

                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Pending Profile Update Request',
                            component: PendingProfileUpdateRequest
                        }
                    ]


                },

                {
                    path: 'update-request-colleges/:id?',
                    meta: {
                        requiresAuth: true,
                        permission: 'list-college-update-request',
                        label: 'College updated request',

                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Pending College Update Request',
                            component: PendingCollegeUpdateRequest
                        }
                    ]
                },
                {
                    path: 'pending-colleges',
                    meta: {
                        requiresAuth: true,
                        permission: 'college-verification',
                        label: 'Non Verified colleges'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Non Verified Colleges',
                            component: PendingCollegesList
                        },
                        {
                            path: 'verification/:id/:userId',
                            name: 'CollegeVerification',
                            meta: {
                                label: 'College Verification'
                            },
                            component: CollegeVerification
                        },
                    ]
                },
                {
                    path: 'pending-profiles',
                    meta: {
                        requiresAuth: true,
                        permission: 'profile-verification',
                        label: 'Non Verified Profiles'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Non Verified Profiles',
                            component: PendingProfilesList
                        },
                        {
                            path: 'view/:id',
                            name: 'Profile View',
                            component: ProfileTabs
                        },
                        {
                            path: 'update-requests/:id',
                            name: 'Profile Update Requests',
                            component: ProfileUpdateRequest
                        },
                        {
                            path: 'verification/:id',
                            name: 'ProfileVerification',
                            meta: {
                                label: 'Profile Verification'
                            },
                            component: ProfileVerification
                        },
                    ]
                },
                {
                    path: 'gac',
                    meta: {
                        label: 'Category'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Give And Ask Categories',
                            component: GiveAndAskCategories
                        },
                    ]
                },

                {
                    path: 'reports',
                    meta: {
                        label: 'Reports'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'opportunity',
                            name: 'Opportunity Report',
                            component: OpportunityReports
                        },
                        {
                            path: 'job',
                            name: 'Job Openings Report',
                            component: JobOpeningsReports
                        },
                        {
                            path: 'resources',
                            name: 'Resources Report',
                            component: ResourcesReports
                        },
//                        {
//                            path: 'candidates',
//                            name: 'Candidates Report',
//                            component: CandidatesReports
//                        },
                        {
                            path: 'companies',
                            name: 'Companies Report',
                            component: CompaniesReports
                        },
                        {
                            path: 'users',
                            name: 'Users Report',
                            component: UsersReports
                        },
                        {
                            path: 'groups',
                            name: 'Groups Report',
                            component: GroupsReports
                        },



                    ]
                },


                {
                    path: 'companies',
                    meta: {
                        requiresAuth: true,
                        permission: 'list-profile',
                        label: 'Company Resources'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Company Resources',
                            component: CompaniesResources
                        },
                    ]
                },

                {
                    path: 'calendar-user',
                    meta: {
                        requiresAuth: true,
                       // permission: 'list-get-verified',
                        label: ''
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'view/:id',
                            name: 'Profile View',
                            component: ProfileForVerifier
                        },
                     ]
                },

                {
                    path: 'profile',
                    meta: {
                        requiresAuth: true,
                        permission: 'list-profile',
                        label: 'Profiles'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Profiles',
                            component: Profile
                        },
//                        {
//                            path: 'create',
//                            name: 'Profile Create',
//                            component: ProfileCreate
//                        },
                        {
                            path: 'edit/:id',
                            name: 'Edit Profile',
                            component: ProfileEdit
                        },
                        {
                            path: 'grouprequest/view/:id',
                            name: 'View Group Request',
                            component: GroupRequestView
                        },
                        {
                            path: 'view/:id',
                            name: 'Profile View',
                            component: ProfileTabs
                        },
                        {
                            path: 'otp/:id',
                            name: 'OTP Verification',
                            component: ProfileTabsOtp
                        },
                        {
                            path: 'all/:user_id?',
                            name: 'Details',
                            component: ProfileAllEntities
                        },
                        {
                            path: 'all/posts/view/:id?/:ids?',
                            name: 'Post View',
                            component: PostView
                        },
                        {
                            path: 'all/opportunities/view/:id?/:ids?',
                            name: 'Opportunity View',
                            component: OpportunityView
                        },
                        {
                            path: 'all/business-traveller/view/:id?/:ids?',
                            name: 'Business Travel View',
                            component: BusinessView,
                        },
                        {
                            path: 'all/resource/view/:id?/:ids?',
                            name: 'Resource View',
                            component: ResourceView
                        },
//                        {
//                            path: 'all/job-openings/view/:id?/:ids?',
//                            name: 'Recruitment View',
//                            component: RecruitmentView
//                        },
//                        {
//                            path: 'all/candidate/view/:id?/:ids?',
//                            name: 'Candidates View',
//                            component: CandidateView
//                        },
                        {
                            path: 'social-links/:id',
                            name: 'ProfileLinks',
                            component: ProfileSocialLinks
                        },
                        {
                            path: 'update-requests/:id',
                            name: 'Profile Update Request',
                            component: ProfileUpdateRequest
                        },
                        {
                            path: 'verification/:id',
                            name: 'ProfileVerification',
                            meta: {
                                label: 'Profile Verification'
                            },
                            component: ProfileVerification
                        },
                        {
                            path: 'my-gac/:group_id/:user_id',
                            name: 'Give And Ask Categories',
                            component: MyGiveAndAskCategories
                        },
                        {
                            path: 'gac/detail/:group_id',
                            name: 'Give And Ask Categories Detail',
                            component: MyGiveAndAskCategoriesDetail
                        },
                        {
                            path: 'gac/chat/:room_id',
                            name: 'Chat',
                            component: GAndAChat
                        },
                        {
                            path: 'poll/comments/:poll_id',
                            name: 'Poll Comments',
                            component: PollComments
                        },
                        {
                            path: 'poll/users_responses/:poll_id',
                            name: 'Poll User Responses',
                            component: PollUserResponses
                        },
                        {
                            path: 'poll/users_reports/:poll_id',
                            name: 'Poll User Reports',
                            component: PollUserReports
                        },
                        {
                            path: 'event/users_reports/:event_id',
                            name: 'Event User Reports',
                            component: EventUserReports
                        },
                        {
                            path: 'event/comments/:poll_id',
                            name: 'Event Comments',
                            component: EventComments
                        },
                        {
                            path: 'edit-plan-permissions/:id',
                            name: 'Edit Plan Permissions',
                            component: EditPlanPermissions
                        },
                        {
                            path: 'view-plan-permissions/:id',
                            name: 'View Plan Permissions',
                            component: ViewPlanPermissions
                        },
                    ]
                },

                {
                    path: 'profile/get-verified',
                    meta: {
                        requiresAuth: true,
                        permission: 'list-get-verified',
                        label: 'Verification Requests'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Verification Requests',
                            component: VerificationRequestList
                        },
                    ]
                },
                {
                    path: 'profile/registered',
                    meta: {
                        requiresAuth: true,
                        permission: 'list-new-registered',
                        label: 'New Registered Account'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'NewRegisteredAccount',
                            component: NewRegistration
                        },
                    ]
                },

                {
                    path: 'roles',
                    meta: {
                        label: 'Roles',
                        requiresAuth: true,
                        permission: 'list-role',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Role',
                            component: Role,
                        },
                        {
                            path: 'create',
                            name: 'Role Create',
                            component: RoleCreate,
                        },
                        {
                            path: 'edit/:id',
                            name: 'Role Edit',
                            component: RoleEdit
                        },
                    ]
                },
                {
                    path: 'resource',
                    meta: {
                        label: 'Resources',
                        requiresAuth: true,
                        permission: 'list-resource',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Resources',
                            component: Resource,
                        },
                        {
                            path: 'create',
                            name: 'ResourceCreate',
                            meta: {
                                label: 'Resource Create'
                            },
                            component: ResourceCreate,
                        },
                        {
                            path: 'view/:id/:user_id?',
                            name: 'ResourceView',
                            meta: {
                                label: 'Resource View'
                            },
                            component: ResourceView
                        },
                        {
                            path: 'edit/:id',
                            name: 'ResourceEdit',
                            meta: {
                                label: 'Resource Edit'
                            },
                            component: ResourceEdit
                        },
                        {
                            path: 'response/view/:id',
                            name: 'Resource View',
                            meta: {
                                label: 'Resource View'
                            },
                            component: ResourceView
                        },
                    ]
                },
                {
                    path: 'discount',
                    meta: {
                        label: 'Discounts',
                        requiresAuth: true,
                        permission: 'list-discount',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Discounts',
                            component: Discount,
                        },
                        {
                            path: 'create',
                            name: 'Discount Create',
                            component: DiscountCreate,
                        },
                        {
                            path: 'edit/:id',
                            name: 'Discount Edit',
                            component: DiscountEdit
                        },
                        {
                            path: 'view/:id',
                            name: 'Discount View',
                            component: DiscountView
                        },
                    ]
                },
                {
                    path: 'company',
                    meta: {
                        label: 'Partner Agencies',
                        requiresAuth: true,
                        permission: 'list-company',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Partner Agencies',
                            component: Company,
                        },
                        {
                            path: 'create',
                            name: 'Partner Agency Create',
                            component: CompanyCreate,
                        },
                        {
                            path: 'edit/:id',
                            name: 'Partner Agency Edit',
                            component: CompanyEdit
                        },
                        {
                            path: 'view/:id',
                            name: 'Partner Agency View',
                            component: CompanyView
                        },
                    ]
                },
                {
                    path: 'branch',
                    meta: {
                        label: 'Branches',
                        requiresAuth: true,
                        permission: 'list-branch',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Branches',
                            component: Branch,
                        }
                    ]
                },
                {
                    path: 'discount-users',
                    meta: {
                        label: 'Discount Users',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Discount Users',
                            component: DiscountUsers,
                        },
                        {
                            path: 'view/:id',
                            name: 'Discount Users View',
                            component: DiscountUsersView
                        },
                    ]
                },
                {
                    path: 'college',
                    meta: {
                        label: 'College',
                        requiresAuth: true,
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'College',
                            component: College,
                        },
                        {
                            path: 'view/:id/:userId?',
                            name: 'College View',
                            component: CollegeView
                        },
                        {
                            path: 'employer/view/:id',
                            name: 'College Employer View',
                            component: CollegeEmployer
                        },
                        {
                            path: 'staff/view/:id',
                            name: 'College Staff View',
                            component: CollegeStaff
                        },
                        {
                            path: 'edit-college-plan-permissions/:id',
                            name: 'Edit College Plan Permissions',
                            component: EditCollegePlanPermissions
                        },
                        {
                            path: 'grouprequest/view/:id',
                            name: 'View Group Request',
                            component: CollegeGroupRequestView
                        },
                        {
                            path: 'my-gac/:group_id/:user_id',
                            name: 'Give And Ask Categories',
                            component: CollegeMyGiveAndAskCategories
                        },
                        {
                            path: 'gac/chat/:room_id',
                            name: 'Chat',
                            component: CollegeGAndAChat
                        },
                        {
                            path: 'poll/comments/:poll_id',
                            name: 'Poll Comments',
                            component: CollegePollComments
                        },
                        {
                            path: 'poll/users_responses/:poll_id',
                            name: 'Poll User Responses',
                            component: CollegePollUserResponses
                        },
                        {
                            path: 'poll/users_reports/:poll_id',
                            name: 'Poll User Reports',
                            component: CollegePollUserReports
                        },
                        {
                            path: 'event/users_reports/:event_id',
                            name: 'Event User Reports',
                            component: CollegeEventUserReports
                        },
                        {
                            path: 'event/comments/:event_id',
                            name: 'Event Comments',
                            component: CollegeEventComments
                        },
                    ]
                },
                {
                    path: 'viewer',
                    meta: {
                        label: 'Viewer',
                        requiresAuth: true
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'pdf/:id',
                            name: 'Viewer',
                            component: Viewer,
                        },
                        {
                            path: 'doc/:id',
                            name: 'Viewer',
                            component: ViewerDoc,
                        },
                        {
                            path: 'xls/:id',
                            name: 'Viewer',
                            component: ViewerXls,
                        },
                        {
                            path: 'reg/:id',
                            name: 'Viewer',
                            component: ViewerRegDoc,
                        },
                    ]
                },
                {
                    path: 'platform-ratting',
                    meta: {
                        label: 'Platforms',
                        requiresAuth: true,
                        permission: 'list-website-ratting',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Platforms',
                            component: PlatformRatting,
                        }
                    ]
                },

                {
                    path: 'language',
                    meta: {
                        label: 'Language',
                        requiresAuth: true,
                        permission: 'list-language',
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Language',
                            component: Language
                        },
                    ]
                },
                {
                    path: 'admin-settings',
                    meta: {
                        requiresAuth: true,
                        permission: 'list-admin-settings',
                        label: 'Admin Settings',

                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Admin Settings',
                            component: AdminSettings
                        }
                    ]
                },

                {
                    path: 'transactions',
                    redirect: '',
                    name: 'Transactions',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Transactions List',
                            requiresAuth: true,
                            component: TransactionsList,
                        },
                        {
                            path: 'view/:id',
                            name: 'View Transaction',
                            requiresAuth: true,
                            component: TransactionView
                        },
                    ]
                },


                {
                    path: 'charts',
                    name: 'Charts',
                    component: Charts
                },
                {
                    path: 'widgets',
                    name: 'Widgets',
                    component: Widgets
                },
                {
                    path: 'users',
                    meta: {
                        label: 'Users'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: '',
                            name: 'Users',
                            component: Users
                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'User Details'
                            },
                            name: 'User',
                            component: User
                        }
                    ]
                },

            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    meta: {
                        label: 'Page 404'
                    },
                    component: Page404
                },
                {
                    path: '403',
                    name: 'Page403',
                    meta: {
                        label: 'Page 403'
                    },
                    component: Page403
                },
                {
                    path: '500',
                    name: 'Page500',
                    meta: {
                        label: 'Page 500'
                    },
                    component: Page500
                },
            ]
        },


    ]
}
export default router
