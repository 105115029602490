module.exports = {
    title: "Profile Verification",
    social_title: 'Social Profile Links',
    crud: {
        create: "Create Profile",
        edit: "Profile Verification",
        view_social: 'Social Links',
        social_edit: 'Update Links',
        verification: 'Verification',
    },
    info: {
        verification_not_added_yet: 'Documents not uploaded yet!',
    },
    table: {
        name: "Name",
        user: 'Company',
        registeredNumber: "Registered number",
        address: "address",
        regDoc: 'Reg Doc',
        regDate: 'Reg Date',
        userVerified: 'User verified',
        companyVerified: 'Company verified',
        domainVerified: 'Domain verified',
        documents: 'Documents',
        status: 'Status',
    },

    form: {
        name: "Name",
        registerName: "Registered Name",
        scheduleVideoMeeting: "Schedule Video Meeting",
        documents: 'Document',
        user: 'Company',
        registeredNumber: "Registered Number",
        address: "Address",
        regDoc: 'Registered Doc',
        regDate: 'Registered Date',
        userVerified: 'User verified',
        companyVerified: 'Company verified',
        domainVerified: 'Domain verified',
        jobTitleVerified: 'Job Title verified',
        status: 'Status',
        vdocuments: 'Verification Company Documents',
        vuserdocuments: 'Verification User Documents',
        viewDocuments: 'View Document',
        'doc': 'View File',
        'pdf': 'View Pdf file',
        companyIdentificationNumber: 'Company Identification Number',
        userIdentificationNumber: 'User Identification Number',
        clickToverify: 'Click to Verify',
        reason: 'Add Rejected Reason',
        calenderLink: 'Send Calender Link',
        reSendCalenderLink: 'Resend Calender Link',
        rejectedType: 'Rejected Type'
    },
    validation: {
        required: {
            name: 'Name is required.',
            registeredNumber: 'Registered number is required.',
            address: 'Address is required.',
            rejectedType: 'Rejected Type is required.',
            reason:'Reason is required.',
        },
        minLength: {
            name: 'Name must have at least',
            type: 'Type must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
