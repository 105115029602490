module.exports = {
    title: "Domain",
    crud: {
        create: "Create Domain",
        edit: "Edit Domain",
        view: "View Domain",
    },
    table: {
        title: "Title",
        isActive: 'Active',
    },
    form: {
        title: "Title",
        isActive: 'Active',
    },
    validation: {
        required: {
            title: 'Title is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
