module.exports = {
    title: "Educations",
    crud: {
        create: "Create Education",
        edit: "Edit Education",
        view: "View Education",
        merge: "Merge Education",
        other: "Other Education",
    },
    table: {
        title: "Education",
        isDefault: "Is Default",
    },
    form: {
        title: "Title",
        newtitle: "New title",
        duplicate: "Move To Education",
    },
    validation: {
        required: {
            title: 'Title is required',
        },
        minLength: {
            title: 'title must have at least',
        },
        common: {
            letter: 'letters.',
        }
    },
}
